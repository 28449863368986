import React, {useState} from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper, Typography
} from '@mui/material';
import config from '../config';

const baseUrl = config.apiBaseUrl;

const WaitingList = () => {
    const [email, setEmail] = useState('');
    const [items, setItems] = useState({
        ears: false,
        clothes: false,
        toys: false,
        vouchers: false
    });
    const [priceRange, setPriceRange] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const selectedItems = Object.keys(items).filter(item => items[item]);
        const data = {
            email,
            items: selectedItems,
            priceRange
        };
        try {
            const response = await fetch(`${baseUrl}/api/Competition/waitinglist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                alert('Successfully submitted!');
            } else {
                alert('Failed to submit!');
            }
        } catch (error) {
            console.error('Error submitting the form', error);
            alert('Failed to submit!');
        }
    };

    const handleItemChange = (event) => {
        setItems({
            ...items,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <Paper>
            <Typography variant="h3" >Competition Waiting List</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox value={items.ears} checked={items.ears} onChange={handleItemChange} name="ears"/>}
                        label="Ears"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.clothes} checked={items.clothes} onChange={handleItemChange} name="clothes"/>}
                        label="Clothes"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.toys} checked={items.toys} onChange={handleItemChange} name="toys"/>}
                        label="Toys"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.vouchers} checked={items.vouchers} onChange={handleItemChange} name="vouchers"/>}
                        label="Vouchers"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.other} checked={items.other} onChange={handleItemChange} name="other"/>}
                        label="Other"
                    />
                    {items.other && (
                        <TextField
                            label="Other Item"
                            value={items.otherText || ''}
                            onChange={(e) => setItems({...items, otherText: e.target.value})}
                            fullWidth
                            margin="normal"
                        />
                    )}

                </FormGroup>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Price Range</InputLabel>
                    <Select
                        value={priceRange}
                        onChange={(e) => setPriceRange(e.target.value)}
                        required
                    >
                        <MenuItem value="low">Low (£0 - £3)</MenuItem>
                        <MenuItem value="medium">Medium (£3 - £8)</MenuItem>
                        <MenuItem value="high">High (£9 - £15)</MenuItem>
                    </Select>
                </FormControl>
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </form>
        </Paper>
    );
};

export default WaitingList;