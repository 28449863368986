import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Container
} from '@mui/material';
import config from '../config';

const baseUrl = config.apiBaseUrl;

const DisneyQueueTimes = () => {
  const [parks, setParks] = useState([]);
  const [selectedPark, setSelectedPark] = useState('');
  const [queueTimes, setQueueTimes] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    fetch('/DisneyDistances.json')
      .then(response => response.json())
      .then(data => setParks(data.parks));
  }, []);

  useEffect(() => {
    if (selectedPark) {
      fetch(`${baseUrl}/api/RideTimes/get-ride-times?selectedPark=${selectedPark}`)
        .then(response => response.json())
        .then(data => {
          const combinedRides = data.lands.flatMap(land =>
            land.rides.map(ride => ({
              ...ride,
              landName: land.name
            }))
          );
          setQueueTimes(combinedRides);
        });
    }
  }, [selectedPark]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favoriteRides')) || [];
    setFavorites(storedFavorites);
  }, []);

  const handleParkChange = (event) => {
    setSelectedPark(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSortChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleFavoriteToggle = (ride) => {
    let updatedFavorites;
    if (favorites.some(fav => fav.id === ride.id)) {
      updatedFavorites = favorites.filter(fav => fav.id !== ride.id);
    } else {
      updatedFavorites = [...favorites, ride];
    }
    setFavorites(updatedFavorites);
    localStorage.setItem('favoriteRides', JSON.stringify(updatedFavorites));
  };

  const filteredQueueTimes = queueTimes.filter(queue => queue.name.toLowerCase().includes(filter.toLowerCase()));
  const sortedQueueTimes = filteredQueueTimes.sort((a, b) => sortOrder === 'asc' ? a.wait_time - b.wait_time : b.wait_time - a.wait_time);

  return (
    <Container>
      <FormControl fullWidth>
        <InputLabel id="park-select-label">Select Park</InputLabel>
        <Select
          labelId="park-select-label"
          value={selectedPark}
          onChange={handleParkChange}
        >
          {parks.map(park => (
            <MenuItem key={park.id} value={park.id}>{park.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedPark === '' ? <p>Please select a park</p> :
          <>
            <h2>Favorite Rides</h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Land Name</TableCell>
                    <TableCell>Ride Name</TableCell>
                    <TableCell>Open/Closed</TableCell>
                    <TableCell align="right">Wait Time (minutes)</TableCell>
                    <TableCell align="right">Favorite</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {favorites.map(fav => (
                      <TableRow key={fav.id}>
                        <TableCell>{fav.landName}</TableCell>
                        <TableCell>{fav.name}</TableCell>
                        <TableCell
                            style={{color: fav.is_open ? 'green' : 'red'}}>{fav.is_open ? "Open" : "Closed"}</TableCell>
                        <TableCell align="right">{fav.wait_time}</TableCell>
                        <TableCell align="right">
                          <Button onClick={() => handleFavoriteToggle(fav)}>
                            RemoveS
                          </Button>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TextField
                label="Filter by ride name"
                value={filter}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleSortChange}>
              Sort by wait time ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
            </Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Land Name</TableCell>
                    <TableCell>Ride Name</TableCell>
                    <TableCell>Open/Closed</TableCell>
                    <TableCell align="right">Wait Time (minutes)</TableCell>
                    <TableCell align="right">Favorite</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedQueueTimes.map(queue => (
                      <TableRow key={queue.id}>
                        <TableCell>{queue.landName}</TableCell>
                        <TableCell>{queue.name}</TableCell>
                        <TableCell
                            style={{color: queue.is_open ? 'green' : 'red'}}>{queue.is_open ? "Open" : "Closed"}</TableCell>
                        <TableCell align="right">{queue.wait_time}</TableCell>
                        <TableCell align="right">
                          <Button onClick={() => handleFavoriteToggle(queue)}>
                            {favorites.some(fav => fav.id === queue.id) ? 'Remove' : 'Add'}
                          </Button>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
      }
    </Container>
  );
};

export default DisneyQueueTimes;