import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';

const Comperition = () => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);

  useEffect(() => {
    fetch(`/api/competition/${id}`)
      .then(response => response.json())
      .then(data => {
        setCompetition(data);
      })
      .catch(error => {
        console.error('Error fetching competition data:', error);
      });
  }, [id]);

  if (!competition) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Paper style={{ padding: 16, marginTop: 16 }}>
        <Grid container spacing={6}>
          <Grid size={12}>
            <Typography variant="h4" component="h1">
              {competition.Title}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1">
              {competition.Description}
            </Typography>
          </Grid>
          <Grid size={6}>
            <img src="https://via.placeholder.com/250" alt="Competition"/>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Start Date: {new Date(competition.StartDate).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                End Date: {new Date(competition.EndDate).toLocaleDateString()}
              </Typography>
            </Grid>
            {competition.PaymentRequired && (
                <Grid size={12}>
                  <Typography variant="body2">
                    Cost: ${competition.Cost}
                  </Typography>
                </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6, lg: 3 }}>
              <Button variant="contained" color="primary">
                Sign In with TikTok <img src="https://via.placeholder.com/25" alt="TikTok logo"/>
              </Button>
            </Grid>
            <Grid size={{xs: 6, lg: 3}}>
            <Button variant="contained" color="secondary">
              Register to Enter 
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Comperition;